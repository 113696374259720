//
// Toast
//

// toast css
.noheadtoast {
    border-radius: $border-radius;
    border: 0;
    .toast-header {
        border-bottom: 0;
        border-radius: $border-radius;
        background-color: transparent;
        padding: 0.75rem 1rem;
        border: 0;
        .btn-close {
            margin-left: auto;
            margin-right: 0;
        }
    }
}
