//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//
@function toRem($value) {
  $remValue: ($value / 16)+rem;
  @return $remValue;
}

// Theme colors
// Primary
$theme: #06A5F0;
$primary: if(isDarkMode(), #3699ff, #06A5F0);
$primary-active: if(isDarkMode(), #187de4, #06A5F0);
$primary-light: if(isDarkMode(), #212e48, #cee4ec);
$primary-inverse: #ffffff;
$text-muted: #6d6e6f !default;

// Success
$success: if(isDarkMode(), #0bb783, #0a9347);
$success-active: if(isDarkMode(), #04aa77, #47be7d);
$success-light: if(isDarkMode(), #1c3238, #cee9da);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #8950fc, #7239ea);
$info-active: if(isDarkMode(), #7337ee, #5014d0);
$info-light: if(isDarkMode(), #2f264f, #e5f4f8);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #d01f45);
$danger-active: if(isDarkMode(), #ee2d41, #d9214e);
$danger-light: if(isDarkMode(), #3a2434, #fae8ec);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #f9c527);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fdf3d3);
$warning-inverse: #ffffff;

//dark
$dark-light: if(isDarkMode(), #000000, #e2e2e2);

//added by sonal 20-04-2022
@function toRem($value) {
  $remValue: ($value / 16)+rem;
  @return $remValue;
}

// @function toRem($value) {
//     $remValue: ($value / 16) + rem;
//     @return $remValue;
// }
$gray-100: if(isDarkMode(), #111111, #ebebeb) !default;
$gray-300: if(isDarkMode(), #323248, #ebebeb) !default;
$gray-900: if(isDarkMode(), #ffffff, #111111) !default;
$gray-500: if(isDarkMode(), #565674, #6d6e6f) !default;

$input-solid-bg: $gray-100  !default; // Custom variable
// $input-btn-padding-y:0.4375rem !default;
$input-btn-padding-y: 0.4375rem !default;
$input-btn-padding-x: 0.5625rem !default;
$input-btn-padding-y-sm: 0.4375rem !default;
$btn-padding-x: 0.5625rem !default;
$btn-padding-x-sm: 0.5625rem !default;
$input-border-color: $gray-100  !default;
$input-color: $gray-900  !default;
$input-focus-border-color: $theme;
$form-label-font-size: 1rem !default;
$btn-font-size: 1rem !default;
$bg-light: #ebebeb;
$form-check-input-width-sm: toRem(18) !default;
$light: #ebebeb;
$page-bg: #f7f7f7;
$input-solid-color: #111111;
$card-header-height: 65px;
$form-check-input-border-radius: 3px;
$card-inner-border-radius: toRem(6);
$card-border-radius: toRem(6);
$modal-header-padding: toRem(16) toRem(30);
$pagination-font-size: toRem(14);
$pagination-font-weight: 400;
$pagination-item-height: 30px;
$pagination-item-space: toRem(5);
$form-select-font-size: toRem(14);
$form-switch-height: 1.875rem !default;
$form-switch-width: 2.8125rem !default;
$input-font-size: toRem(14);
$dropdown-font-size: toRem(14);
$table-hover-bg: #f9f9f9;
$modal-md: 470px;
//$root-font-size:14px !important;
//$input-btn-padding-x: 0.522rem !default;